.main {
  background: #FFFFFF;
  position: relative;
  z-index: 3;
}
.main-raised {
  margin: -100px 30px 0;
  border-radius: 6px;
  min-height: 600px;
  background-color: #E8E8E8;
  background-color: transparent;
}
/*# sourceMappingURL=Content.css.map */
.backdrop {
  position: fixed;
  /* Stay in place */
  z-index: 1300;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
.message-window {
  z-index: 1301;
  font-weight: inherit;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  margin-top: 130px !important;
}
@media (max-height: 500px) {
  .message-window {
    flex-basis: 98% !important;
    max-width: 98% !important;
    margin-top: 85px !important;
  }
  .message-window br {
    display: none;
  }
  .message-window .header-success {
    height: 55px !important;
  }
}
.message-window .avatar {
  width: 70px;
  height: 70px;
  margin-left: -33px;
  margin-bottom: -37px;
  background-color: #b6f7b4;
  border: 2px solid white;
}
.message-window a {
  color: #3c4858;
}
.message-window .submit-button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.message-window .header-success {
  background-color: #b6f7b4;
  -webkit-clip-path: polygon(100% 0, 100% 79%, 50% 100%, 0 79%, 0 0);
          clip-path: polygon(100% 0, 100% 79%, 50% 100%, 0 79%, 0 0);
  text-align: center;
  height: 90px;
}
/*# sourceMappingURL=MessageWindow.css.map */
.home-content-box {
  margin-top: 15px;
}
.home-content-box h1 {
  margin: 0px;
  padding: 0px;
  font-size: 1.8em;
  margin-bottom: 10px;
}
.home-content-box h2 {
  margin: 0px;
  padding: 0px;
  font-size: inherit;
  line-height: inherit;
}
.home-content-box h3 {
  margin: 0px;
  padding: 0px;
  font-size: 0.7em;
  font-weight: 500;
  color: #3C4858;
  line-height: 0.8em;
}
.home-content-box .box-container {
  padding: 15px;
  margin: 10px 6px;
}
.home-content-box .box-container.no-padding {
  padding: 0px !important;
}
.home-content-box :not(.no-padding) .top-progress {
  margin: -15px;
  margin-bottom: 10px;
}
.home-content-box .card-content {
  padding-top: 0px;
}
.home-content-box .no-padding .card-content {
  padding: 0px;
}
.home-content-box th {
  font-size: 0.9em;
  color: #333;
}
.home-content-box .editor {
  border: 1px solid #92d4fa;
  box-shadow: 0 0 3px #92d4fa;
  -moz-box-shadow: 0 0 3px #92d4fa;
  -webkit-box-shadow: 0 0 3px #92d4fa;
  padding: 2px;
}
.home-content-box .mainButton {
  margin: 30px 0px 0px 10px;
}
.home-content-box .text-content {
  font-size: 1.2em;
  line-height: 1.5em;
}
.home-content-box .text-content .line {
  min-height: 1.2em;
}
.home-content-box .text-content :not(:first-child):not(.bullet) {
  margin-top: 15px;
}
.home-content-box .text-content .bullet > * {
  padding-top: 3px;
}
.home-content-box.plain {
  margin: 15px 0px;
  padding: 0px;
}
.home-content-box.plain .box-container {
  margin: 0px;
  padding: 0px;
}
.home-content-box.plain .box-container > * {
  padding: 0px;
  margin: 0px;
}
.home-content-box.fix {
  padding-bottom: 20px;
}
.home-content-box.fix .box-container {
  height: 100%;
}
/*# sourceMappingURL=HomeContentBox.css.map */
.timeline-container {
  padding: 0px;
}
.timeline-item {
  background-color: #fff;
  margin: 0 10px;
  min-height: 100px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 96px;
  position: relative;
}
.background-masker {
  background: #fff;
  position: absolute;
}
/* Every thing below this is just positioning */
.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 0px;
  right: 0;
  height: 10px;
}
.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 0px;
  height: 8px;
  width: 10px;
  display: none;
}
.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}
.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}
.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 70%;
  right: 0;
}
.background-masker.subheader-right {
  left: 55%;
}
.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}
.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-fourth-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-fourth-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}
.background-masker.content-top {
  height: 20px;
}
.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-fourth-end {
  width: auto;
  left: 88%;
  right: 0;
  top: 60px;
  height: 8px;
}
.background-masker.content-second-line {
  top: 68px;
}
.background-masker.content-second-end {
  left: 98%;
  top: 74px;
}
.background-masker.content-third-line {
  top: 82px;
}
.background-masker.content-third-end {
  left: 60%;
  top: 88px;
}
.background-masker.content-fourth-line {
  top: 114px;
}
.background-masker.content-fourth-end {
  left: 60%;
  top: 114px;
}
/*# sourceMappingURL=ContentLoader.css.map */
.news-box {
  margin-top: 15px;
}
.news-box .box-container {
  margin: 10px 6px;
  margin-bottom: 0px;
}
/*# sourceMappingURL=NewsBox.css.map */
.review {
  margin: 0px 50px;
  height: 190px;
}
.review .review-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.review .review-container .left {
  display: flex;
  flex-direction: column;
  min-width: 130px;
  align-content: center;
  justify-content: center;
}
.review .review-container .left .avatar {
  margin: 0 auto;
  margin-bottom: 10px;
}
.review .review-container .left .avatar.purple {
  background-color: #673ab7;
}
.review .review-container .left .avatar.orange {
  background-color: #ff5722;
}
.review .review-container .right {
  padding: 0px 15px;
}
.review .review-container .right .name {
  font-weight: bolder;
}
.review .review-container .right .place {
  color: #333;
  font-size: 12px;
  padding: 0;
  line-height: 14px;
  padding-bottom: 10px;
}
.review .review-container .right .content {
  line-height: 15px;
}
@media (max-width: 576px) {
  .review {
    height: 250px;
  }
  .review .review-container .left {
    min-width: 40px;
  }
  .review .review-container .right .content {
    font-size: 11px;
    line-height: 13px;
  }
  .review .review-container .sm-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
}
/*# sourceMappingURL=Review.css.map */
.review-box {
  margin-top: 15px;
  margin-bottom: 20px !important;
}
.review-box h2 {
  margin: 0px;
  padding: 16px 24px;
  font-size: 1.5em;
  line-height: 1.5em;
}
.review-box .box-container {
  padding: 15px;
  margin: 10px 6px;
  margin-bottom: 0px;
}
.review-box .box-container.no-padding {
  padding: 0px !important;
}
.review-box .mainButton {
  margin: 30px 0px 0px 10px;
}
/*# sourceMappingURL=ReviewBox.css.map */
.carousel-icon {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  height: 100%;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 20px;
  z-index: 2;
  opacity: 0.5;
}
.slick-next::before {
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: black;
  font-size: 30px;
  width: 100%;
  font-family: 'Material Icons', serif !important;
  content: '\E5CC' !important;
}
.slick-prev::before {
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: black;
  font-size: 30px;
  width: 100%;
  font-family: 'Material Icons', serif !important;
  content: '\E5CB' !important;
  margin-left: -15px;
}
button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  width: 20px !important;
}
.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "\2022";
  text-align: center;
  opacity: 1;
  background-color: #777 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: all 300ms linear;
}
/*# sourceMappingURL=ReviewCarousel.css.map */
.services {
  margin: 30px;
  margin-bottom: 0px;
}
.services .service {
  height: 2.6em;
  margin-top: 0.8em;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services .caption {
  flex: 1 1 auto;
  line-height: 1em;
  font-size: 1.4em;
  margin-left: 20px;
  vertical-align: middle;
}
.services i {
  font-size: 2.5em;
}
.services i .mf {
  font-size: 1em;
}
.services .mainButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
@media (max-width: 576px) {
  .services {
    font-size: 0.7em;
    margin: 5px;
  }
}
@media (max-width: 768px) {
  .services {
    font-size: 0.8em;
  }
}
/*# sourceMappingURL=Services.css.map */
.place-info {
  padding-bottom: 25px;
  margin-top: 15px;
  position: relative;
}
.place-info h2 {
  font-weight: 500;
}
.place-info .box-container {
  margin: 10px 6px;
  margin-bottom: 0px;
  padding-bottom: 50px;
  height: 100%;
}
.place-info .box-container > div {
  padding: 0px 10px;
  padding-bottom: 20px;
}
.place-info .card-content {
  padding: 0px 10px;
}
.place-info .footer {
  position: absolute;
  height: 50px;
  bottom: 20px;
  left: 10px;
  right: 30px;
  color: #000;
}
.place-info .footer .distance {
  margin-top: 10px;
  float: right;
  font-weight: 500;
}
.place-info .footer .icon {
  color: #2f9bff;
}
/*# sourceMappingURL=PlaceInfo.css.map */
.virtual-view {
  margin-top: 15px;
  margin-bottom: 20px !important;
}
.virtual-view h2 {
  margin: 0px;
  padding: 0px;
  font-size: inherit;
  line-height: inherit;
}
.virtual-view .text-content {
  font-size: 1.2em;
  line-height: 1.5em;
}
.virtual-view .text-content .line {
  min-height: 1.2em;
}
.virtual-view .text-content :not(:first-child):not(.bullet) {
  margin-top: 15px;
}
.virtual-view .text-content .bullet > * {
  padding-top: 3px;
}
.virtual-view .box-container {
  padding: 15px;
  margin: 10px 6px;
  margin-bottom: 0px;
}
.virtual-view .box-container.no-padding {
  padding: 0px !important;
}
.virtual-view .mainButton {
  margin: 30px 0px 0px 10px;
}
/*# sourceMappingURL=VirtualView.css.map */
.table td.first {
  width: 30px;
}
.table th,
.table td {
  padding: 3px;
}
.table th .dash,
.table td .dash {
  margin: 0px 5px;
}
@media (max-width: 400px) {
  .table th .dash,
  .table td .dash {
    display: none !important;
  }
  .table th .inline,
  .table td .inline {
    display: block;
  }
}
.table td.first button {
  padding: 4px !important;
}
.table td.first button svg {
  font-size: 22px;
}
/*# sourceMappingURL=Table.css.map */
.price-list-admin .submit-button {
  margin-top: 10px;
}
.price-list-admin .conflict .warning {
  color: red;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 20px;
}
/*# sourceMappingURL=PriceListAdmin.css.map */
.checkbox-bail.green svg {
  color: green !important;
}
.conflict .warning {
  color: red;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 20px;
}
/*# sourceMappingURL=ReservationList.css.map */
.reservation-view .submit-button {
  margin-top: 10px;
}
/*# sourceMappingURL=ReservationView.css.map */

.confirmation-dialog {
  width: 100%;
  max-width: 360;
}
/*# sourceMappingURL=ConfirmationDialog.css.map */
.price-list {
  width: 100%;
  margin-top: 5px;
  overflow-x: auto;
}
.price-list .table .row:nth-of-type(odd) {
  background-color: #f5f5f5;
}
.price-list .footnote {
  color: #333;
}
.price-list .inline {
  display: block;
}
.price-list .center {
  text-align: center;
}
.price-list .gray {
  color: #999;
}
/*# sourceMappingURL=PriceList.css.map */
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
/* VARIABLES */
:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #d6d5d5;
  --neutral-color: #fff;
}
/* GENERAL */
.col-center {
  justify-content: center;
  text-align: center;
}
.cell-inner {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
/* Calendar */
.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
}
.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
}
.calendar .header .icon:first-of-type {
  margin-left: 1em;
}
.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}
.calendar .body .cell-inner:hover {
  transition: 0.5s ease-out;
}
.calendar .body .cell {
  justify-content: center;
  display: flex;
  align-items: center;
}
.calendar .body .cell-inner.occupied:after {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  content: '\E53A';
  font-size: 2em;
  text-align: center;
  justify-content: center;
}
.calendar .body .cell-inner.reserved:after {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  content: '\E889';
  font-size: 2em;
  text-align: center;
  justify-content: center;
}
.calendar .body .vacancy {
  background: #e2ffdb;
}
.calendar .body .vacancy .number {
  color: black;
}
.calendar .body .reserved {
  background: #faf0e6;
}
.calendar .body .occupied {
  background: #c70707;
}
.calendar .body .half {
  background: #e2ffdb;
  background: linear-gradient(to right bottom, #c70707 50%, #e2ffdb 50%);
}
.calendar .body .half-reserved {
  background: #e2ffdb;
  background: linear-gradient(to right bottom, #faf0e6 50%, #e2ffdb 50%);
}
.calendar .body .half-inverted {
  background: #e2ffdb;
  background: linear-gradient(to right bottom, #e2ffdb 50%, #c70707 50%);
}
.calendar .body .half-inverted-reserved {
  background: #e2ffdb;
  background: linear-gradient(to right bottom, #e2ffdb 50%, #faf0e6 50%);
}
.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}
.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}
.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.1;
  transition: 0.5s ease-in;
}
.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}
.today {
  z-index: 10;
  color: black;
}
.today::before {
  content: '';
  position: absolute;
  right: -6px;
  top: -8px;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #2660f566;
  border-radius: 24px;
  font-size: 30px;
  z-index: 1;
}
/*# sourceMappingURL=Calendar.css.map */
.reservation-form {
  margin-top: 30px;
  margin-bottom: 0px;
}
.reservation-form .buttons {
  display: flex;
  align-items: center;
  padding: 0.9375rem 1.875rem;
}
.reservation-form .buttons button {
  margin: 0 auto;
  margin-top: 15px;
}
/*# sourceMappingURL=ReservationForm.css.map */
.parallax {
  height: 60vh;
  max-height: 1000px;
  overflow: hidden;
  position: relative;
  background-position: center center;
  background-size: cover;
  margin: -15px;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  color: white;
}
.parallax-filter:before {
  background: rgba(0, 0, 0, 0.6);
}
.parallax-filter:after,
.parallax-filter:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: '';
}
/*# sourceMappingURL=Parallax.css.map */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
.icon-home {
  content: '\E53A';
  font-size: 2em;
  text-align: center;
  justify-content: center;
}
/*# sourceMappingURL=HeaderLinks.css.map */
footer {
  margin-top: 50px;
  background-color: #777;
  color: #ccc;
  padding-top: 20px;
}
footer p {
  margin: 0px;
  font-size: 16px;
}
footer .top-inner {
  border-radius: 20px !important;
  color: #424542;
  background-color: white;
  height: 40px;
  width: 40px;
  position: relative;
  padding-left: 9px;
  line-height: 40px;
  border-color: rgba(66, 69, 66, 0.7);
  border-width: 1px;
  border-style: solid;
}
footer .top-inner .chevron {
  font-size: 30px;
  margin-left: -4px;
  margin-top: -6px;
}
footer .top-inner:hover {
  background-color: rgba(197, 197, 197, 0.7);
  cursor: pointer;
}
footer .top-outer {
  border-radius: 25px !important;
  color: #424542;
  background-color: #e5e5e5;
  height: 50px;
  width: 50px;
  position: relative;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  top: -14px;
  padding-left: 5px;
  padding-top: 5px;
}
footer .top-outer i {
  font-size: 20px;
}
footer .p-rel {
  position: relative;
}
footer ul {
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
}
footer li {
  display: block;
  color: #ccc;
  text-align: left;
}
footer a {
  color: #333;
  text-decoration: underline;
}
footer a:hover {
  color: #222;
  text-decoration: none;
}
footer li:before {
  /*Using a Bootstrap glyphicon as the bullet point*/
  content: "\E315";
  font-family: "Material Icons";
  font-size: 12px;
  float: left;
  margin-top: 0px;
  margin-left: -17px;
  color: #333;
}
footer .copyright {
  margin-top: 15px;
  background-color: #252525 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  height: 44px;
  line-height: 30px;
  color: white;
}
footer .copyright > div {
  text-align: center;
  flex: 1 1 100%;
  line-height: 17px;
}
footer .copyright a {
  color: #ccc;
}
footer .copyright .author {
  line-height: 25px;
  font-size: 1.5em;
}
footer .copyright .credits div {
  display: inline;
}
footer .title {
  margin-top: 30px;
  margin-bottom: 0px;
  color: #ccc;
  font-size: 1.4em;
  font-family: "Roboto Condensed", sans-serif;
}
footer .quicklinks .entry {
  border-left: 1px solid #ccc;
  margin-left: 10px;
  line-height: 25px;
}
footer .quicklinks ul {
  margin-left: 12px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
footer .quicklinks li {
  display: block;
  color: white;
  padding: 0px !important;
  margin: 0px;
}
footer .quicklinks li a {
  color: #333;
  text-decoration: underline;
}
footer .quicklinks li a:hover {
  color: #222;
  text-decoration: underline;
}
footer .quicklinks li:before {
  content: "";
}
@media (max-width: 576px) {
  footer .title,
  footer .entry,
  footer p {
    padding-left: 30px;
  }
}
/*# sourceMappingURL=Footer.css.map */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
header {
  font-size: 2em;
}
@media (max-height: 500px) {
  header {
    font-size: 1em;
  }
}
.App-title {
  font-size: 1.5em;
}
.App-intro {
  font-size: large;
}
.grid-item {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: auto;
}
.grid-container {
  margin-right: -15px;
  margin-left: -15px;
  width: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.boxShadow {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42) 0 4px 25px 0 rgba(0, 0, 0, 0.12) 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.brand .container {
  color: #ffffff;
  text-align: left;
}
.title {
  color: #ffffff;
  display: inline-block;
  position: relative;
  /*min-height: 32px;
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
  */
  text-decoration: none;
  font-size: 2.8em;
}
.motto {
  color: rgba(206, 206, 206);
  position: relative;
  z-index: 12;
}
.side-gallery {
  margin-left: 30px;
}
.subtitle {
  font-size: 1.313rem;
  max-width: 500px;
  margin: 10px 0 0;
}
.link {
  text-decoration: none;
}
.textCenter {
  text-align: center;
}
.kontakt .caption {
  font-size: 1.4em;
  line-height: 1.8em;
}
.kontakt div {
  font-size: 1.1em;
  line-height: 1.4em;
}
.kontakt .map {
  margin: -40px;
  border-radius: 4px;
  margin-top: 20px;
}
@media only screen and (max-width: 576px) {
  .kontakt {
    font-size: 0.8em;
  }
}
.page-motto .title {
  z-index: 12;
}
@media only screen and (max-width: 576px) {
  .page-motto {
    font-size: 0.7em;
    padding: 0px 20px;
    margin-top: 10px;
  }
}
@media only screen and (max-height: 576px) {
  .page-motto {
    display: none !important;
  }
  .page-motto .motto {
    display: none !important;
  }
}
@media only screen and (max-height: 768px) {
  .page-motto .motto {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .title {
    font-size: 2em;
  }
  .main-raised {
    margin-top: -80px;
  }
}
@media only screen and (max-width: 1000px) {
  .title {
    font-size: 2em;
  }
  .side-gallery {
    margin-left: 0px;
  }
}
/*# sourceMappingURL=App.css.map */
/*! =========================================================
 *
 * Material Kit React v1.3.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 *
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Available with purchase of license from https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)
 *
 * ========================================================= */
 html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  
  body {
    font-size: 1rem; }
  
  body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em; }
  
  h1, h2, h3, h4, h5, h6 {
    color: inherit; }
  
  h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px; }
  
  h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px; }
  
  h1 {
    font-size: 3.3125rem;
    line-height: 1.15em; }
  
  h2 {
    font-size: 2.25rem;
    line-height: 1.5em; }
  
  h3 {
    font-size: 1.5625rem;
    line-height: 1.4em; }
  
  h4 {
    font-size: 1.125rem;
    line-height: 1.5em; }
  
  h5 {
    font-size: 1.0625rem;
    line-height: 1.55em; }
  
  h6 {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500; }
  
  p {
    font-size: 14px;
    margin: 0 0 10px; }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent; }
  
  body {
    background-color: #E5E5E5;
    color: #3C4858;
    margin: 0;
    font-size: 1rem;
    text-align: left; }
  
  legend {
    border-bottom: 0; }
  
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box; }
    *:focus {
      outline: 0; }
  
  a {
    color: #9c27b0;
    text-decoration: none;
    background-color: transparent; }
    a:hover, a:focus {
      color: #89229b;
      text-decoration: none; }
  
  label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 400; }
  
  small {
    font-size: 75%;
    color: #777;
    font-weight: 400; }
  
  img {
    vertical-align: middle;
    border-style: none; }
  
  form {
    margin-bottom: 1.125rem; }
  
  /*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
  /* Functional styling;
  * These styles are required for noUiSlider to function.
  * You don't need to change these rules to apply your design.
  */
  /*
  
  This file was modified by Creative-Tim
  
  */
  .noUi-target,
  .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    touch-action: none;
    user-select: none;
    box-sizing: border-box; }
  
  .noUi-target {
    position: relative;
    direction: ltr; }
  
  .noUi-base,
  .noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; }
  
  /* Wrapper for all connect elements.
  */
  .noUi-connects {
    overflow: hidden;
    z-index: 0; }
  
  .noUi-connect,
  .noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: 0 0; }
  
  /* Offset direction
  */
  html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0; }
  
  /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
  .noUi-vertical .noUi-origin {
    width: 0; }
  
  .noUi-horizontal .noUi-origin {
    height: 0; }
  
  .noUi-handle {
    position: absolute; }
  
  .noUi-state-tap .noUi-connect,
  .noUi-state-tap .noUi-origin {
    transition: transform 0.3s; }
  
  .noUi-state-drag * {
    cursor: inherit !important; }
  
  /* Slider size and handle placement;
  */
  .noUi-horizontal {
    height: 2px;
    margin: 15px 0; }
  
  .noUi-horizontal .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -10px;
    top: -6px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #9c27b0;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  
  .noUi-vertical {
    width: 2px;
    margin: 0 15px;
    height: 100%; }
  
  .noUi-vertical .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -6px;
    top: -10px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #9c27b0;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  
  html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -4px;
    left: auto; }
  
  /* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
  .noUi-target {
    background-color: #c8c8c8;
    border-radius: 3px; }
  
  .noUi-connects {
    border-radius: 3px; }
  
  .noUi-connect {
    background: #888;
    border-radius: 3px;
    transition: background 450ms; }
  
  /* Handles and cursors;
  */
  .noUi-draggable {
    cursor: ew-resize; }
  
  .noUi-vertical .noUi-draggable {
    cursor: ns-resize; }
  
  .noUi-handle {
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
    -webkit-transition: 300ms ease 0s;
    -moz-transition: 300ms ease 0s;
    -ms-transition: 300ms ease 0s;
    -o-transform: 300ms ease 0s;
    transition: 300ms ease 0s; }
    .noUi-handle:focus {
      outline: none; }
  
  .noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }
  
  /* Disabled state;
  */
  [disabled] .noUi-connect {
    background: #B8B8B8; }
  
  [disabled].noUi-target,
  [disabled].noUi-handle,
  [disabled] .noUi-handle {
    cursor: not-allowed; }
  
  /* Base;
  *
  */
  .noUi-pips,
  .noUi-pips * {
    box-sizing: border-box; }
  
  .noUi-pips {
    position: absolute;
    color: #999; }
  
  /* Values;
  *
  */
  .noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center; }
  
  .noUi-value-sub {
    color: #ccc;
    font-size: 10px; }
  
  /* Markings;
  *
  */
  .noUi-marker {
    position: absolute;
    background: #CCC; }
  
  .noUi-marker-sub {
    background: #AAA; }
  
  .noUi-marker-large {
    background: #AAA; }
  
  /* Horizontal layout;
  *
  */
  .noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%; }
  
  .noUi-value-horizontal {
    transform: translate(-50%, 50%); }
  
  .noUi-rtl .noUi-value-horizontal {
    transform: translate(50%, 50%); }
  
  .noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px; }
  
  .noUi-marker-horizontal.noUi-marker-sub {
    height: 10px; }
  
  .noUi-marker-horizontal.noUi-marker-large {
    height: 15px; }
  
  /* Vertical layout;
  *
  */
  .noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%; }
  
  .noUi-value-vertical {
    transform: translate(0, -50%, 0);
    padding-left: 25px; }
  
  .noUi-rtl .noUi-value-vertical {
    transform: translate(0, 50%); }
  
  .noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px; }
  
  .noUi-marker-vertical.noUi-marker-sub {
    width: 10px; }
  
  .noUi-marker-vertical.noUi-marker-large {
    width: 15px; }
  
  .noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap; }
  
  .noUi-horizontal .noUi-tooltip {
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%; }
  
  .noUi-vertical .noUi-tooltip {
    transform: translate(0, -50%);
    top: 50%;
    right: 120%; }
  
  .noUi-target .noUi-handle {
    border: 1px solid #333; }
  
  .noUi-target.slider-primary .noUi-connect, .noUi-target.slider-primary.noUi-connect {
    background-color: #9c27b0; }
  
  .noUi-target.slider-primary .noUi-handle {
    border-color: #9c27b0; }
  
  .noUi-target.slider-info .noUi-connect, .noUi-target.slider-info.noUi-connect {
    background-color: #00bcd4; }
  
  .noUi-target.slider-info .noUi-handle {
    border-color: #00bcd4; }
  
  .noUi-target.slider-success .noUi-connect, .noUi-target.slider-success.noUi-connect {
    background-color: #4caf50; }
  
  .noUi-target.slider-success .noUi-handle {
    border-color: #4caf50; }
  
  .noUi-target.slider-warning .noUi-connect, .noUi-target.slider-warning.noUi-connect {
    background-color: #ff9800; }
  
  .noUi-target.slider-warning .noUi-handle {
    border-color: #ff9800; }
  
  .noUi-target.slider-danger .noUi-connect, .noUi-target.slider-danger.noUi-connect {
    background-color: #f44336; }
  
  .noUi-target.slider-danger .noUi-handle {
    border-color: #f44336; }
  
  .noUi-target.slider-rose .noUi-connect, .noUi-target.slider-rose.noUi-connect {
    background-color: #e91e63; }
  
  .noUi-target.slider-rose .noUi-handle {
    border-color: #e91e63; }
  
  /*!
  * https://github.com/YouCanBookMe/react-datetime
  */
  .rdt {
    position: relative; }
    .rdt .rdtPicker {
      transition: all 150ms linear;
      margin-top: -20px;
      visibility: hidden;
      display: block;
      opacity: 0; }
    .rdt.rdtOpen .rdtPicker {
      opacity: 1;
      visibility: visible;
      margin-top: 0; }
    .rdt input.form-control {
      border: 0;
      background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
      background-size: 0 2px, 100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(100% - 1px);
      background-color: rgba(0, 0, 0, 0);
      transition: background 0s ease-out;
      float: none;
      box-shadow: none;
      border-radius: 0;
      font-weight: 400;
      width: 100%;
      height: 36px;
      padding: 7px 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.42857;
      display: block;
      width: 100%;
      color: #555; }
    .rdt input.form-control:focus {
      outline: none;
      background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s; }
  
  .rdtPicker {
    display: none;
    position: absolute;
    width: 260px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px; }
    .rdtPicker:before {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: -5px;
      left: 10px;
      right: auto;
      color: #FFFFFF;
      border-bottom: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent; }
    .rdtPicker:after {
      border-bottom: .4em solid #FFFFFF;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      top: -5px;
      left: 10px; }
  
  .rdtPicker {
    display: block;
    top: 40px; }
  
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static; }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
    padding: 5px;
    border-radius: 4px; }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: #fff !important;
    border-collapse: collapse; }
  
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    padding: 1px; }
  
  .rdtPicker td {
    cursor: pointer; }
  
  .rdtDay {
    height: 30px;
    line-height: 33px;
    width: 30px;
    text-align: center;
    padding: 0px;
    border-radius: 50%; }
    .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
      background-color: #9c27b0 !important;
      color: #fff;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }
  
  .rdtDays tr .dow {
    border-bottom: 1px solid #E3E3E3;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: inherit;
    padding-bottom: 5px;
    padding-top: 10px; }
  
  .rdtDays tr .rdtOld,
  .rdtDays tr .rdtNew {
    color: #bdbdbd; }
  
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer; }
  
  .rdtPicker td.rdtToday {
    position: relative; }
  
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff; }
  
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker td span.rdtOld {
    color: #999999; }
  
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none; }
  
  .rdtPicker th.rdtSwitch {
    width: 50px;
    padding: 5px;
    border-radius: 4px; }
  
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
    border-radius: 50%;
    line-height: 33px; }
  
  .rdtPicker .dow,
  .rdtPicker th.rdtSwitch,
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev,
  .rdtPicker .rdtTimeToggle {
    color: #9c27b0; }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    /* Konqueror */
    /* Firefox */
    /* Internet Explorer/Edge */
    user-select: none; }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker thead tr:first-child th {
    cursor: pointer; }
  
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee; }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer; }
  
  .rdtPicker button:hover {
    background-color: #eee; }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%; }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer; }
  
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee; }
  
  .rdtCounters {
    display: inline-block; }
  
  .rdtCounters > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%; }
  
  .rdtCounters .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent; }
  
  .rdtCounter {
    height: 100px; }
  
  .rdtCounter {
    width: 40px; }
    .rdtCounter .rdtCount {
      padding: 7px;
      height: 40px;
      border: 1px solid transparent; }
  
  .rdtCounters .rdtCounter:last-child .rdtCount {
    color: #9c27b0;
    border-radius: 50%;
    border: 1px solid #9c27b0; }
  
  .rdtCounterSeparator {
    padding: 7px;
    line-height: 100px; }
  
  .rdtCounter .rdtBtn {
    line-height: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: #eee;
    transition: all 60ms ease-in;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    /* Konqueror */
    /* Firefox */
    /* Internet Explorer/Edge */
    user-select: none; }
  
  .rdtCounter .rdtBtn:hover {
    background: #eee;
    color: #797979; }
  
  .rdtCounter .rdtCount {
    font-size: inherit;
    line-height: 25px; }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px; }
  
  .rdtMilli input {
    width: 100%;
    font-size: inherit;
    margin-top: 37px; }
  
  .rdtMonths,
  .rdtYears {
    padding-bottom: 10px; }
    .rdtMonths .rdtMonth,
    .rdtMonths .rdtYear,
    .rdtYears .rdtMonth,
    .rdtYears .rdtYear {
      display: inline-block;
      width: 56px;
      height: 56px;
      line-height: 56px;
      margin: 3px 3px;
      cursor: pointer;
      border-radius: 50%;
      text-align: center; }
      .rdtMonths .rdtMonth.rdtActive,
      .rdtMonths .rdtYear.rdtActive,
      .rdtYears .rdtMonth.rdtActive,
      .rdtYears .rdtYear.rdtActive {
        background-color: #9c27b0 !important;
        color: #fff; }
  
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
    @media (min-width: 768px) {
      .slick-slider .slick-caption {
        display: block !important; } }
    .slick-slider .slick-caption {
      padding-bottom: 45px;
      position: absolute;
      right: 15%;
      bottom: 20px;
      left: 15%;
      z-index: 10;
      padding-top: 20px;
      color: #ffffff;
      text-align: center;
      z-index: 3;
      display: none; }
    .slick-slider .slick-slide > div:first-child {
      position: relative; }
    .slick-slider .slick-icons {
      position: relative;
      top: 5px; }
    .slick-slider .slick-image {
      width: 100% !important;
      display: inline-flex !important; }
  
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  
  .slick-list:focus {
    outline: none; }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0); }
  
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: ''; }
  
  .slick-track:after {
    clear: both; }
  
  .slick-loading .slick-track {
    visibility: hidden; }
  
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
  
  [dir='rtl'] .slick-slide {
    float: right; }
  
  .slick-slide img {
    display: block; }
  
  .slick-slide.slick-loading img {
    display: none; }
  
  .slick-slide.dragging img {
    pointer-events: none; }
  
  .slick-initialized .slick-slide {
    display: block; }
  
  .slick-loading .slick-slide {
    visibility: hidden; }
  
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  
  .slick-arrow.slick-hidden {
    display: none; }
  
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    height: 100%;
    padding: 0;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    color: transparent;
    outline: none;
    background: transparent;
    width: 15%;
    z-index: 2;
    opacity: 0.5; }
  
  .slick-prev {
    left: 0; }
    .slick-prev::before {
      content: "\F053";
      font-weight: 600;
      font-family: Font Awesome\ 5 Free;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      color: white;
      font-size: 30px;
      width: 100%; }
  
  .slick-next {
    right: 0; }
    .slick-next::before {
      content: "\F054";
      font-weight: 600;
      font-family: Font Awesome\ 5 Free;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      color: #FFF;
      font-size: 30px;
      width: 100%; }
  
  .slick-list {
    z-index: 1; }
  
  .slick-dots {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 3; }
  
  .slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer; }
  
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0; }
  
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent; }
    .slick-dots li button::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      content: "\2022";
      text-align: center;
      opacity: 1;
      background-color: #FFF;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      transition: all 300ms linear; }
  
  .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    top: -3px; }
