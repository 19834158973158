/*! =========================================================
 *
 * Material Kit React v1.3.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 *
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Available with purchase of license from https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)
 *
 * ========================================================= */
 html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  
  body {
    font-size: 1rem; }
  
  body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em; }
  
  h1, h2, h3, h4, h5, h6 {
    color: inherit; }
  
  h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px; }
  
  h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px; }
  
  h1 {
    font-size: 3.3125rem;
    line-height: 1.15em; }
  
  h2 {
    font-size: 2.25rem;
    line-height: 1.5em; }
  
  h3 {
    font-size: 1.5625rem;
    line-height: 1.4em; }
  
  h4 {
    font-size: 1.125rem;
    line-height: 1.5em; }
  
  h5 {
    font-size: 1.0625rem;
    line-height: 1.55em; }
  
  h6 {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500; }
  
  p {
    font-size: 14px;
    margin: 0 0 10px; }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent; }
  
  body {
    background-color: #E5E5E5;
    color: #3C4858;
    margin: 0;
    font-size: 1rem;
    text-align: left; }
  
  legend {
    border-bottom: 0; }
  
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box; }
    *:focus {
      outline: 0; }
  
  a {
    color: #9c27b0;
    text-decoration: none;
    background-color: transparent; }
    a:hover, a:focus {
      color: #89229b;
      text-decoration: none; }
  
  label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 400; }
  
  small {
    font-size: 75%;
    color: #777;
    font-weight: 400; }
  
  img {
    vertical-align: middle;
    border-style: none; }
  
  form {
    margin-bottom: 1.125rem; }
  
  /*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
  /* Functional styling;
  * These styles are required for noUiSlider to function.
  * You don't need to change these rules to apply your design.
  */
  /*
  
  This file was modified by Creative-Tim
  
  */
  .noUi-target,
  .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  
  .noUi-target {
    position: relative;
    direction: ltr; }
  
  .noUi-base,
  .noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; }
  
  /* Wrapper for all connect elements.
  */
  .noUi-connects {
    overflow: hidden;
    z-index: 0; }
  
  .noUi-connect,
  .noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  
  /* Offset direction
  */
  html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0; }
  
  /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
  .noUi-vertical .noUi-origin {
    width: 0; }
  
  .noUi-horizontal .noUi-origin {
    height: 0; }
  
  .noUi-handle {
    position: absolute; }
  
  .noUi-state-tap .noUi-connect,
  .noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s; }
  
  .noUi-state-drag * {
    cursor: inherit !important; }
  
  /* Slider size and handle placement;
  */
  .noUi-horizontal {
    height: 2px;
    margin: 15px 0; }
  
  .noUi-horizontal .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -10px;
    top: -6px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #9c27b0;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  
  .noUi-vertical {
    width: 2px;
    margin: 0 15px;
    height: 100%; }
  
  .noUi-vertical .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -6px;
    top: -10px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #9c27b0;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  
  html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -4px;
    left: auto; }
  
  /* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
  .noUi-target {
    background-color: #c8c8c8;
    border-radius: 3px; }
  
  .noUi-connects {
    border-radius: 3px; }
  
  .noUi-connect {
    background: #888;
    border-radius: 3px;
    -webkit-transition: background 450ms;
    transition: background 450ms; }
  
  /* Handles and cursors;
  */
  .noUi-draggable {
    cursor: ew-resize; }
  
  .noUi-vertical .noUi-draggable {
    cursor: ns-resize; }
  
  .noUi-handle {
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
    -webkit-transition: 300ms ease 0s;
    -moz-transition: 300ms ease 0s;
    -ms-transition: 300ms ease 0s;
    -o-transform: 300ms ease 0s;
    transition: 300ms ease 0s; }
    .noUi-handle:focus {
      outline: none; }
  
  .noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }
  
  /* Disabled state;
  */
  [disabled] .noUi-connect {
    background: #B8B8B8; }
  
  [disabled].noUi-target,
  [disabled].noUi-handle,
  [disabled] .noUi-handle {
    cursor: not-allowed; }
  
  /* Base;
  *
  */
  .noUi-pips,
  .noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  
  .noUi-pips {
    position: absolute;
    color: #999; }
  
  /* Values;
  *
  */
  .noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center; }
  
  .noUi-value-sub {
    color: #ccc;
    font-size: 10px; }
  
  /* Markings;
  *
  */
  .noUi-marker {
    position: absolute;
    background: #CCC; }
  
  .noUi-marker-sub {
    background: #AAA; }
  
  .noUi-marker-large {
    background: #AAA; }
  
  /* Horizontal layout;
  *
  */
  .noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%; }
  
  .noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%); }
  
  .noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%); }
  
  .noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px; }
  
  .noUi-marker-horizontal.noUi-marker-sub {
    height: 10px; }
  
  .noUi-marker-horizontal.noUi-marker-large {
    height: 15px; }
  
  /* Vertical layout;
  *
  */
  .noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%; }
  
  .noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%, 0);
    padding-left: 25px; }
  
  .noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%); }
  
  .noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px; }
  
  .noUi-marker-vertical.noUi-marker-sub {
    width: 10px; }
  
  .noUi-marker-vertical.noUi-marker-large {
    width: 15px; }
  
  .noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap; }
  
  .noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%; }
  
  .noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%; }
  
  .noUi-target .noUi-handle {
    border: 1px solid #333; }
  
  .noUi-target.slider-primary .noUi-connect, .noUi-target.slider-primary.noUi-connect {
    background-color: #9c27b0; }
  
  .noUi-target.slider-primary .noUi-handle {
    border-color: #9c27b0; }
  
  .noUi-target.slider-info .noUi-connect, .noUi-target.slider-info.noUi-connect {
    background-color: #00bcd4; }
  
  .noUi-target.slider-info .noUi-handle {
    border-color: #00bcd4; }
  
  .noUi-target.slider-success .noUi-connect, .noUi-target.slider-success.noUi-connect {
    background-color: #4caf50; }
  
  .noUi-target.slider-success .noUi-handle {
    border-color: #4caf50; }
  
  .noUi-target.slider-warning .noUi-connect, .noUi-target.slider-warning.noUi-connect {
    background-color: #ff9800; }
  
  .noUi-target.slider-warning .noUi-handle {
    border-color: #ff9800; }
  
  .noUi-target.slider-danger .noUi-connect, .noUi-target.slider-danger.noUi-connect {
    background-color: #f44336; }
  
  .noUi-target.slider-danger .noUi-handle {
    border-color: #f44336; }
  
  .noUi-target.slider-rose .noUi-connect, .noUi-target.slider-rose.noUi-connect {
    background-color: #e91e63; }
  
  .noUi-target.slider-rose .noUi-handle {
    border-color: #e91e63; }
  
  /*!
  * https://github.com/YouCanBookMe/react-datetime
  */
  .rdt {
    position: relative; }
    .rdt .rdtPicker {
      -webkit-transition: all 150ms linear;
      -moz-transition: all 150ms linear;
      -o-transition: all 150ms linear;
      -ms-transition: all 150ms linear;
      transition: all 150ms linear;
      margin-top: -20px;
      visibility: hidden;
      display: block;
      opacity: 0; }
    .rdt.rdtOpen .rdtPicker {
      opacity: 1;
      visibility: visible;
      margin-top: 0; }
    .rdt input.form-control {
      border: 0;
      background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
      background-size: 0 2px, 100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(100% - 1px);
      background-color: rgba(0, 0, 0, 0);
      transition: background 0s ease-out;
      float: none;
      box-shadow: none;
      border-radius: 0;
      font-weight: 400;
      width: 100%;
      height: 36px;
      padding: 7px 0;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.42857;
      display: block;
      width: 100%;
      color: #555; }
    .rdt input.form-control:focus {
      outline: none;
      background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s; }
  
  .rdtPicker {
    display: none;
    position: absolute;
    width: 260px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px; }
    .rdtPicker:before {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: -5px;
      left: 10px;
      right: auto;
      color: #FFFFFF;
      border-bottom: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent; }
    .rdtPicker:after {
      border-bottom: .4em solid #FFFFFF;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      top: -5px;
      left: 10px; }
  
  .rdtPicker {
    display: block;
    top: 40px; }
  
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static; }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
    padding: 5px;
    border-radius: 4px; }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: #fff !important;
    border-collapse: collapse; }
  
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    padding: 1px; }
  
  .rdtPicker td {
    cursor: pointer; }
  
  .rdtDay {
    height: 30px;
    line-height: 33px;
    width: 30px;
    text-align: center;
    padding: 0px;
    border-radius: 50%; }
    .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
      background-color: #9c27b0 !important;
      color: #fff;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }
  
  .rdtDays tr .dow {
    border-bottom: 1px solid #E3E3E3;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: inherit;
    padding-bottom: 5px;
    padding-top: 10px; }
  
  .rdtDays tr .rdtOld,
  .rdtDays tr .rdtNew {
    color: #bdbdbd; }
  
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer; }
  
  .rdtPicker td.rdtToday {
    position: relative; }
  
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff; }
  
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker td span.rdtOld {
    color: #999999; }
  
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none; }
  
  .rdtPicker th.rdtSwitch {
    width: 50px;
    padding: 5px;
    border-radius: 4px; }
  
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
    border-radius: 50%;
    line-height: 33px; }
  
  .rdtPicker .dow,
  .rdtPicker th.rdtSwitch,
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev,
  .rdtPicker .rdtTimeToggle {
    color: #9c27b0; }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed; }
  
  .rdtPicker thead tr:first-child th {
    cursor: pointer; }
  
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee; }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer; }
  
  .rdtPicker button:hover {
    background-color: #eee; }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%; }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer; }
  
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee; }
  
  .rdtCounters {
    display: inline-block; }
  
  .rdtCounters > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%; }
  
  .rdtCounters .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent; }
  
  .rdtCounter {
    height: 100px; }
  
  .rdtCounter {
    width: 40px; }
    .rdtCounter .rdtCount {
      padding: 7px;
      height: 40px;
      border: 1px solid transparent; }
  
  .rdtCounters .rdtCounter:last-child .rdtCount {
    color: #9c27b0;
    border-radius: 50%;
    border: 1px solid #9c27b0; }
  
  .rdtCounterSeparator {
    padding: 7px;
    line-height: 100px; }
  
  .rdtCounter .rdtBtn {
    line-height: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: #eee;
    -webkit-transition: all 60ms ease-in;
    -moz-transition: all 60ms ease-in;
    -o-transition: all 60ms ease-in;
    -ms-transition: all 60ms ease-in;
    transition: all 60ms ease-in;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
  
  .rdtCounter .rdtBtn:hover {
    background: #eee;
    color: #797979; }
  
  .rdtCounter .rdtCount {
    font-size: inherit;
    line-height: 25px; }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px; }
  
  .rdtMilli input {
    width: 100%;
    font-size: inherit;
    margin-top: 37px; }
  
  .rdtMonths,
  .rdtYears {
    padding-bottom: 10px; }
    .rdtMonths .rdtMonth,
    .rdtMonths .rdtYear,
    .rdtYears .rdtMonth,
    .rdtYears .rdtYear {
      display: inline-block;
      width: 56px;
      height: 56px;
      line-height: 56px;
      margin: 3px 3px;
      cursor: pointer;
      border-radius: 50%;
      text-align: center; }
      .rdtMonths .rdtMonth.rdtActive,
      .rdtMonths .rdtYear.rdtActive,
      .rdtYears .rdtMonth.rdtActive,
      .rdtYears .rdtYear.rdtActive {
        background-color: #9c27b0 !important;
        color: #fff; }
  
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
    @media (min-width: 768px) {
      .slick-slider .slick-caption {
        display: block !important; } }
    .slick-slider .slick-caption {
      padding-bottom: 45px;
      position: absolute;
      right: 15%;
      bottom: 20px;
      left: 15%;
      z-index: 10;
      padding-top: 20px;
      color: #ffffff;
      text-align: center;
      z-index: 3;
      display: none; }
    .slick-slider .slick-slide > div:first-child {
      position: relative; }
    .slick-slider .slick-icons {
      position: relative;
      top: 5px; }
    .slick-slider .slick-image {
      width: 100% !important;
      display: inline-flex !important; }
  
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  
  .slick-list:focus {
    outline: none; }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: ''; }
  
  .slick-track:after {
    clear: both; }
  
  .slick-loading .slick-track {
    visibility: hidden; }
  
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
  
  [dir='rtl'] .slick-slide {
    float: right; }
  
  .slick-slide img {
    display: block; }
  
  .slick-slide.slick-loading img {
    display: none; }
  
  .slick-slide.dragging img {
    pointer-events: none; }
  
  .slick-initialized .slick-slide {
    display: block; }
  
  .slick-loading .slick-slide {
    visibility: hidden; }
  
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  
  .slick-arrow.slick-hidden {
    display: none; }
  
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    height: 100%;
    padding: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    color: transparent;
    outline: none;
    background: transparent;
    width: 15%;
    z-index: 2;
    opacity: 0.5; }
  
  .slick-prev {
    left: 0; }
    .slick-prev::before {
      content: "\f053";
      font-weight: 600;
      font-family: Font Awesome\ 5 Free;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      color: white;
      font-size: 30px;
      width: 100%; }
  
  .slick-next {
    right: 0; }
    .slick-next::before {
      content: "\f054";
      font-weight: 600;
      font-family: Font Awesome\ 5 Free;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      color: #FFF;
      font-size: 30px;
      width: 100%; }
  
  .slick-list {
    z-index: 1; }
  
  .slick-dots {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 3; }
  
  .slick-dots li, .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer; }
  
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0; }
  
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent; }
    .slick-dots li button::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      content: "\2022";
      text-align: center;
      opacity: 1;
      background-color: #FFF;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      transition: all 300ms linear; }
  
  .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    top: -3px; }